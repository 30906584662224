import React from 'react';
import logo from './img/logo/fcms logo-50.png';
import './App.css';
import {Col, Space, Row} from "antd";

function App() {
  let xiaohongshu:string = "https://www.xiaohongshu.com/user/profile/64cabb56000000000b00a477?xhsshare=CopyLink&appuid=633a1cc7000000001901de3d&apptime=1710134953";
  let instagram:string = "https://www.instagram.com/fcms_official?igsh=YzZsbXN5ZzZkb2ly";
  return (
    <div className="App">
      <header className="App-header">
          <Space direction="vertical">
          <img src={logo} className="App-logo" alt="logo" />
              <div></div>
              <Row>
                  <Col span={2}></Col>
                  <Col span={10}>
                      <a href={xiaohongshu} className="link" >小红书</a>
                  </Col>
                  <Col span={10}>
                      <a href={instagram} className="link">Instagram</a>
                  </Col>
                  <Col span={2}></Col>
              </Row>
          </Space>
      </header>
    </div>
  );
}

export default App;
